import React from "react";
import { RouteProps } from "react-router-dom";

export interface RouteItem {
  key: string;
  label: string;
  path: string;
  parentKey: string;
  component: RouteProps["component"];
  noref?: boolean;
}
//Pages
const DraftList = React.lazy(() => import("../pages/DraftList"));
const ImageTab = React.lazy(() => import("../pages/DraftList/imageTab"));
//To import in src/routes/index.ts -> proRoutes
//To import in src/constant/menu.ts -> TWO_COl_MENU_ITEMS
const draftsRouteItem: RouteItem[] = [
  {
    key: "ds-drafts",
    label: "Drafts",
    path: "/traveler/drafts",
    parentKey: "traveler",
    component: DraftList,
  },
  // {
  //   // key: "ds-drafts-document-noref",
  //   key: "ds-drafts",
  //   label: "Document",
  //   path: "/traveler/documentviewer",
  //   parentKey: "traveler",
  //   component: ImageTab,
  //   noref: true,
  // },
];

const popupImageRouteItem: RouteItem[] = [
  {
    // key: "ds-drafts-document-noref",
    key: "ds-drafts",
    label: "Document",
    path: "/documentviewer",
    parentKey: "external",
    component: ImageTab,
    noref: true,
  },
];

export { draftsRouteItem, popupImageRouteItem };
