import { combineReducers } from "redux";
import { takeEvery } from "redux-saga/effects";

import RequestReducer from "./Reducers/request.reducer";
import { requestSaga } from "./Sagas/request.saga";
import { ProActionTypes } from "./Types";

//to import to src/redux/reducer
export default combineReducers({
  Request: RequestReducer,
});

//import to src/redux/saga
export function* watchRequestSaga() {
  yield takeEvery(ProActionTypes.EXEMPLE, requestSaga);
}

export * from "./Actions/request.actions";
