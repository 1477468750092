import { ProActionType, ProActionTypes } from "../Types";

// common success
export const proApiResponseSuccess = (
  actionType: string,
  data: any | {}
): ProActionType => ({
  type: ProActionTypes.API_PRO_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const proApiResponseError = (
  actionType: string,
  error: string
): ProActionType => ({
  type: ProActionTypes.API_PRO_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const exempleAction = (): ProActionType => ({
  type: ProActionTypes.EXEMPLE,
  payload: {},
});
