export enum ProActionTypes {
  API_PRO_RESPONSE_SUCCESS = "@@pro/API_RESPONSE_SUCCESS",
  API_PRO_RESPONSE_ERROR = "@@pro/API_RESPONSE_ERROR",
  EXEMPLE = "@@pro/EXAMPLE",
}

export interface ProActionType {
  type:
    | ProActionTypes.API_PRO_RESPONSE_SUCCESS
    | ProActionTypes.API_PRO_RESPONSE_ERROR
    | ProActionTypes.EXEMPLE;

  payload: {} | string;
}
