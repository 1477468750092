import { put, call } from "redux-saga/effects";
//import axios from "axios";
import { SagaIterator } from "redux-saga";
import { ProActionTypes } from "../Types";
import {
  proApiResponseError,
  proApiResponseSuccess,
} from "../Actions/request.actions";

function* requestSaga({ type }: any): SagaIterator {
  try {
    yield call(console.log, "into saga");
    // const response = yield call(axios.get, "http://toto.com");
    // const data = response.data;

    yield put(proApiResponseSuccess(ProActionTypes.EXEMPLE, {}));
  } catch (error: any) {
    yield put(proApiResponseError(ProActionTypes.EXEMPLE, error));
  }
}

export { requestSaga };
