import { combineReducers } from "redux";

import Auth from "./auth/reducers";
import Layout from "./layout/reducers";
import ProReducer from "../modules/pro/redux";

export default combineReducers({
  Auth,
  Layout,
  Pro: ProReducer,
});
