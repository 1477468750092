import React from "react";
import { RouteProps } from "react-router-dom";

export interface RouteItem {
  key: string;
  label: string;
  path: string;
  parentKey: string;
  component: RouteProps["component"];
}
//Pages
const OrgnanizationList = React.lazy(() => import("../pages/Organizations"));
const CRMDashboard = React.lazy(() => import("../pages/CRM"));
const AnalyticsDashboard = React.lazy(() => import("../pages/Analytics"));

//To import in src/routes/index.ts -> proRoutes
//To import in src/constant/menu.ts -> TWO_COl_MENU_ITEMS
const proRouteItem: RouteItem[] = [
  {
    key: "ds-organizations",
    label: "Organizations",
    path: "/pro/organizations",
    parentKey: "pro",
    component: OrgnanizationList,
  },
  {
    key: "ds-crm",
    label: "CRM",
    path: "/pro/crm",
    parentKey: "pro",
    component: CRMDashboard,
  },
  {
    key: "ds-analytics",
    label: "Analytics",
    path: "/pro/analytics",
    parentKey: "pro",
    component: AnalyticsDashboard,
  },
];

export { proRouteItem };
