// apicore

// constants
import { INIT_PRO_STATE, ProState } from "../States";
import { ProActionTypes } from "../Types";

interface AuthActionType {
  type:
    | ProActionTypes.API_PRO_RESPONSE_SUCCESS
    | ProActionTypes.API_PRO_RESPONSE_ERROR;
  payload: {
    actionType?: string;
    data?: any | {};
    error?: string;
  };
}

const RequestReducer = (
  state: ProState = INIT_PRO_STATE,
  action: AuthActionType
): any => {
  switch (action.type) {
    case ProActionTypes.API_PRO_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case ProActionTypes.EXEMPLE: {
          return {
            ...state,
            counter: state.counter + 1,
          };
        }
        // case ProActionTypes.SIGNUP_USER: {
        //   return {
        //     ...state,
        //     loading: false,
        //     userSignUp: true,
        //   };
        // }
        // case ProActionTypes.LOGOUT_USER: {
        //   return {
        //     ...state,
        //     user: null,
        //     loading: false,
        //     userLogout: true,
        //   };
        // }
        // case ProActionTypes.FORGOT_PASSWORD: {
        //   return {
        //     ...state,
        //     resetPasswordSuccess: action.payload.data,
        //     loading: false,
        //     passwordReset: true,
        //   };
        // }
        default:
          return { ...state };
      }

    case ProActionTypes.API_PRO_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        // case ProActionTypes.LOGIN_USER: {
        //   return {
        //     ...state,
        //     error: action.payload.error,
        //     userLoggedIn: false,
        //     loading: false,
        //   };
        // }
        // case ProActionTypes.SIGNUP_USER: {
        //   return {
        //     ...state,
        //     registerError: action.payload.error,
        //     userSignUp: false,
        //     loading: false,
        //   };
        // }
        // case ProActionTypes.FORGOT_PASSWORD: {
        //   return {
        //     ...state,
        //     error: action.payload.error,
        //     loading: false,
        //     passwordReset: false,
        //   };
        // }
        default:
          return { ...state };
      }
    default:
      return { ...state };
  }
};

export default RequestReducer;
